import axios from "axios";
import { BASE_URL } from "../../config";
const BASE_URLs = `${BASE_URL}/`;

export const saveCategory = (name) => {
  return axios.post(`${BASE_URLs}categories/`, { name, activeStatus: true });
};

export const fetchCategory = (page, sizePerPage) => {
  return axios.get(`${BASE_URLs}categories/?page=${page}&limit=${sizePerPage}`);
};

export const sortCategory = (sortField, sortOrder, limit, page) => {
  if (sortOrder === "asc") {
    return axios.get(
      `${BASE_URLs}categories/?page=${page}&limit=${limit}&sort=-${sortField}`
    );
  } else {
    return axios.get(
      `${BASE_URLs}categories/?page=${page}&limit=${limit}&sort=${sortField}`
    );
  }
};

// Trying to get the count from server however it doesnt send total count hence 10000
export const fetchCategoryCount = () => {
  return axios.get(`${BASE_URLs}categories/?limit=10000`);
};
export const fetchActiveCategory = () => {
  return axios.get(`${BASE_URLs}categories/?activeStatus=true&limit=10000`);
};

export const searchCategory = (name) => {
  return axios.get(`${BASE_URLs}categories/?name=${name}`);
};

export const updateCategory = () => {
  return axios.post(`${BASE_URLs}categories/update/`);
};

export const updateCategoryPut = (id, name, activeStatus) => {
  return axios.put(`${BASE_URLs}/categories/${id}`, { name, activeStatus });
};

// Manufacturer
export const saveManufacturer = (name) => {
  return axios.post(`${BASE_URLs}manufacturers/`, { name, activeStatus: true });
};

export const fetchManufacturer = (page, sizePerPage) => {
  return axios.get(
    `${BASE_URLs}manufacturers?page=${page}&limit=${sizePerPage}`
  );
};

export const updateManufacturerPut = (id, name, activeStatus) => {
  return axios.put(`${BASE_URLs}/manufacturers/${id}`, { name, activeStatus });
};

export const fetchManufacturerCount = () => {
  return axios.get(`${BASE_URLs}manufacturers?limit=10000`);
};

export const fetchActiveManufacturer = () => {
  return axios.get(`${BASE_URLs}manufacturers?activeStatus=true&limit=10000`);
};

export const searchManufacturer = (name) => {
  return axios.get(`${BASE_URLs}manufacturers?name=${name}`);
};

export const sortManufacturer = (sortField, sortOrder, limit, page) => {
  if (sortOrder === "asc") {
    return axios.get(
      `${BASE_URLs}manufacturers/?page=${page}&limit=${limit}&sort=-${sortField}`
    );
  } else {
    return axios.get(
      `${BASE_URLs}manufacturers/?page=${page}&limit=${limit}&sort=${sortField}`
    );
  }
};

//SaveSupplier

export const saveSupplier = (name) => {
  return axios.post(`${BASE_URLs}suppliers/`, { name, activeStatus: true });
};

export const fetchSupplier = (page, sizePerPage) => {
  return axios.get(`${BASE_URLs}suppliers?page=${page}&limit=${sizePerPage}`);
};

export const updateSupplierPut = (id, name, activeStatus) => {
  return axios.put(`${BASE_URLs}/suppliers/${id}`, { name, activeStatus });
};
export const searchSupplier = (name) => {
  return axios.get(`${BASE_URLs}suppliers?name=${name}`);
};

export const fetchSupplierCount = () => {
  return axios.get(`${BASE_URLs}suppliers?limit=10000`);
};

export const fetchActiveSupplier = () => {
  return axios.get(`${BASE_URLs}suppliers?activeStatus=true&limit=10000`);
};

export const fetchPreCategories = () => {
  return axios.get(`${BASE_URLs}pre-categories/?sort=name&limit=100`);
};
// I know I have used a large numbers but there are limited categories in the ecommerce its not a million

export const sortSupplier = (sortField, sortOrder, limit, page) => {
  if (sortOrder === "asc") {
    return axios.get(
      `${BASE_URLs}suppliers/?page=${page}&limit=${limit}&sort=-${sortField}`
    );
  } else {
    return axios.get(
      `${BASE_URLs}suppliers/?page=${page}&limit=${limit}&sort=${sortField}`
    );
  }
};

// Shipping Methods

export const saveshippingMethod = (name) => {
  return axios.post(`${BASE_URLs}shipping-methods/`, { name: name });
};

export const fetchshippingMethod = (page, limit) => {
  return axios.get(
    `${BASE_URLs}/shipping-methods/?page=${page}&limit=${limit}`
  );
};

export const fetchshippingCount = () => {
  return axios.get(`${BASE_URLs}shipping-methods/?limit=10000`);
};

export const updateshippingMethod = (name, id) => {
  return axios.put(`${BASE_URLs}shipping-methods/${id}/`, { name: name });
};

export const sortShippingMethod = (sortField, sortOrder, limit, page) => {
  if (sortOrder === "asc") {
    return axios.get(
      `${BASE_URLs}shipping-methods/?page=${page}&limit=${limit}&sort=-${sortField}`
    );
  } else {
    return axios.get(
      `${BASE_URLs}shipping-methods/?page=${page}&limit=${limit}&sort=${sortField}`
    );
  }
};

// Handling charge

export const saveHandlingCharge = (title, type, value) => {
  return axios.post(`${BASE_URLs}handling-fees/`, {
    title: title,
    type: type,
    value: value,
  });
};

export const fetchHandlingCharge = (page, limit) => {
  return axios.get(`${BASE_URLs}handling-fees/?page=${page}&limit=${limit}`);
};

export const fetchHandlingChargeCount = () => {
  return axios.get(`${BASE_URLs}handling-fees/?limit=10000`);
};

export const updateHandlingChargePut = (id, title, type, value) => {
  return axios.put(`${BASE_URLs}handling-fees/${id}`, { title, type, value });
};

export const sortHandlingCharge = (sortField, sortOrder, limit, page) => {
  if (sortOrder === "asc") {
    return axios.get(
      `${BASE_URLs}handling-fees/?page=${page}&limit=${limit}&sort=-${sortField}`
    );
  } else {
    return axios.get(
      `${BASE_URLs}handling-fees/?page=${page}&limit=${limit}&sort=${sortField}`
    );
  }
};

// Order Management

export const fetchOrders = (page, limit, sort = false, sortOrder) => {
  if (sort) {
    console.log(sort, sortOrder);
    if (sortOrder === "desc") {
      return axios.get(
        `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sort}`
      );
    }
    return axios.get(
      `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sort}`
    );
  }
  return axios.get(`${BASE_URLs}orders/?page=${page}&limit=${limit}`);
};

export const fetchOrdersShipment = (page, limit, sort = false, sortOrder) => {
  if (sort) {
    if (sortOrder === "desc") {
      return axios.get(
        `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sort}&shipmentStatus=PACKED&platformCode[ne]=AMAZONJP`
      );
    }
    return axios.get(
      `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sort}&shipmentStatus=PACKED&platformCode[ne]=AMAZONJP`
    );
  }
  return axios.get(
    `${BASE_URLs}orders/?page=${page}&limit=${limit}&shipmentStatus=PACKED&platformCode[ne]=AMAZONJP`
  );
};

export const orderStatusFetch = (status) => {
  return axios.get(`${BASE_URLs}orders/?shipmentStatus=${status}`);
};
export const otzOrderStatusFetch = (status) => {
  return axios.get(`${BASE_URLs}otz-orders/?shipmentStatus=${status}`);
};

export const fetchOtzOrders = () => {
  return axios.get(`${BASE_URLs}otz-orders/?page=1&limit=1`);
};

export const fetchCreationOtzOrders = (creationDate) => {
  return axios.get(`${BASE_URLs}otz-orders/?createdAt[gt]=${creationDate}`);
};

// Order Notification

export const orderNotification = (date) => {
  return axios.get(`${BASE_URLs}orders/?createdAt[gt]=${date}`);
};

export const updateOrders = (orderId, updatedData) => {
  return axios.put(`${BASE_URLs}orders/${orderId}`, updatedData);
};

export const searchOrders = (page, limit, searchString, status) => {
  return axios.get(
    `${BASE_URLs}orders/search?searchStr=${searchString}&page=${page}&limit=${limit}&&shipmentStatus=${status}`
  );
};
export const searchOrdersShipmentId = (page, limit, ShipmentId, status) => {
  if (status !== "All") {
    return axios.get(
      `${BASE_URLs}orders/?shipmentId=${ShipmentId}&page=${page}&limit=${limit}&shipmentStatus=${status}`
    );
  }
  return axios.get(
    `${BASE_URLs}orders/?shipmentId=${ShipmentId}&page=${page}&limit=${limit}`
  );
};

export const searchOrdersByProductId = (productId, page, limit, status) => {
  if (status !== "All") {
    return axios.get(
      `${BASE_URLs}orders/?product=${productId}&page=${page}&limit=${limit}&sort=-createdAt&shipmentStatus=${status}`
    );
  } else {
    return axios.get(
      `${BASE_URLs}orders/?product=${productId}&page=${page}&limit=${limit}&sort=-createdAt`
    );
  }
};

export const EcFilter = (
  page,
  limit,
  query = false,
  status = false,
  sort = false,
  sortOrder = false
) => {
  if (query && status) {
    if (sort) {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sort}&shipmentStatus=${status}&platformCode=${query}`
        );
      }
      return axios.get(
        `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sort}&shipmentStatus=${status}&platformCode=${query}`
      );
    }
    return axios.get(
      `${BASE_URLs}orders/?page=${page}&limit=${limit}&shipmentStatus=${status}&platformCode=${query}`
    );
  }
  if (query) {
    if (sort) {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sort}&platformCode=${query}`
        );
      }
      return axios.get(
        `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sort}&platformCode=${query}`
      );
    }
    return axios.get(
      `${BASE_URLs}orders/?page=${page}&limit=${limit}&platformCode=${query}`
    );
  }
  if (status) {
    if (sort) {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sort}&shipmentStatus=${status}`
        );
      }
      return axios.get(
        `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sort}&shipmentStatus=${status}`
      );
    }
    return axios.get(
      `${BASE_URLs}orders/?page=${page}&limit=${limit}&shipmentStatus=${status}`
    );
  }
};

// OTZ Orders

export const GetOtzOrders = (page, limit, sortfield, sortOrder, status) => {
  if (status) {
    if (sortfield) {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=${sortfield}&shipmentStatus=${status}`
        );
      }
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=-${sortfield}&shipmentStatus=${status}`
      );
    } else {
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&shipmentStatus=${status}`
      );
    }
  }
  if (sortfield) {
    if (sortOrder === "desc") {
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=${sortfield}`
      );
    }
    return axios.get(
      `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=-${sortfield}`
    );
  }
  return axios.get(`${BASE_URLs}otz-orders/?page=${page}&limit=${limit}`);
};

export const updateOtzOrders = (orderId, updatedData) => {
  return axios.put(`${BASE_URLs}otz-orders/${orderId}`, updatedData);
};

export const searchOtzOrders = (page, limit, searchString) => {
  return axios.get(`${BASE_URLs}otz-orders/search?searchStr=${searchString}`);
};

export const ECSalesFiltersSort = (
  page,
  limit,
  date = false,
  ec = false,
  sortfield = false,
  sortOrder = false,
  from = false,
  to = false
) => {
  console.log(page, limit, date, ec, sortfield, sortOrder, from, to);
  if (date && ec) {
    if (sortfield) {
      if (from && to) {
        if (sortOrder === "desc") {
          console.log("sort from to ec");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED&platformCode=${ec}`
          );
        } else {
          console.log("-sort from to ec");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED&platformCode=${ec}`
          );
        }
      } else if (from && !to) {
        console.log("sort from ec");
        if (sortOrder === "desc") {
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[gte]=${from}&shipmentStatus=COMPLETED&platformCode=${ec}`
          );
        } else {
          console.log("-sort from ec");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[gte]=${from}&shipmentStatus=COMPLETED&platformCode=${ec}`
          );
        }
      } else if (to && !from) {
        console.log("sort to ec");
        if (sortOrder === "desc") {
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[lte]=${to}&shipmentStatus=COMPLETED&platformCode=${ec}`
          );
        } else {
          console.log("-sort to ec");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[lte]=${to}&shipmentStatus=COMPLETED&platformCode=${ec}`
          );
        }
      }
    } else {
      if (from && to) {
        console.log("from to ec");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED&platformCode=${ec}`
        );
      } else if (from && !to) {
        console.log("from ec");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&orderDate[gte]=${from}&shipmentStatus=COMPLETED&platformCode=${ec}`
        );
      } else if (to && !from) {
        console.log("to ec");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&orderDate[lte]=${to}&shipmentStatus=COMPLETED&platformCode=${ec}`
        );
      }
    }
  } else if (date && !ec) {
    if (sortfield) {
      if (from && to) {
        if (sortOrder === "desc") {
          console.log("date sort frm to");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
          );
        } else {
          console.log("date -sort frm to");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
          );
        }
      } else if (from && !to) {
        if (sortOrder === "desc") {
          console.log("date sort frm");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[gte]=${from}&shipmentStatus=COMPLETED`
          );
        } else {
          console.log("date -sort frm");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[gte]=${from}&shipmentStatus=COMPLETED`
          );
        }
      } else if (to && !from) {
        if (sortOrder === "desc") {
          console.log("date sort to");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
          );
        } else {
          console.log("date -sort frm");
          return axios.get(
            `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
          );
        }
      }
    } else {
      if (from && to) {
        console.log(from, to);
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
        );
      } else if (from && !to) {
        console.log("date,from  ");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&orderDate[eq]=${from}&shipmentStatus=COMPLETED`
        );
      } else if (to && !from) {
        console.log("date to ");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&orderDate[eq]=${to}&shipmentStatus=COMPLETED`
        );
      }
    }
  } else if (ec && !date) {
    if (sortfield) {
      if (sortOrder === "desc") {
        console.log("sort ec");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sortfield}&shipmentStatus=COMPLETED&platformCode=${ec}`
        );
      } else {
        console.log("-sort ec");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sortfield}&shipmentStatus=COMPLETED&platformCode=${ec}`
        );
      }
    } else if (ec) {
      console.log("ec");
      return axios.get(
        `${BASE_URLs}orders/?page=${page}&limit=${limit}&shipmentStatus=COMPLETED&platformCode=${ec}`
      );
    }
  } else {
    if (sortfield) {
      if (sortOrder === "desc") {
        console.log("sort");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=${sortfield}&sort=${sortfield}&shipmentStatus=COMPLETED`
        );
      } else {
        console.log("-sort");
        return axios.get(
          `${BASE_URLs}orders/?page=${page}&limit=${limit}&sort=-${sortfield}&sort=${sortfield}&shipmentStatus=COMPLETED`
        );
      }
    } else {
      console.log("Nothing selected");
      return axios.get(
        `${BASE_URLs}orders/?page=${page}&limit=${limit}&shipmentStatus=COMPLETED`
      );
    }
  }
};

// OTZ sales page

export const GetOtzSalesOrders = (
  page,
  limit,
  sortfield,
  sortOrder,
  from,
  to
) => {
  if (sortfield) {
    if (from && to) {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
        );
      }
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
      );
    }
    if (from) {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[gte]=${from}&shipmentStatus=COMPLETED`
        );
      }
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[gte]=${from}&shipmentStatus=COMPLETED`
      );
    }
    if (to) {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=${sortfield}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
        );
      }
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=-${sortfield}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
      );
    }

    if (sortOrder === "desc") {
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=${sortfield}&shipmentStatus=COMPLETED`
      );
    }
    return axios.get(
      `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&sort=-${sortfield}&shipmentStatus=COMPLETED`
    );
  }
  if (!sortfield) {
    console.log(575);
    if (from && to) {
      console.log("from", "to");
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&orderDate[gte]=${from}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
      );
    }
    if (from) {
      console.log("583");
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&orderDate[gte]=${from}&shipmentStatus=COMPLETED`
      );
    }
    if (to) {
      console.log("589");
      return axios.get(
        `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&orderDate[lte]=${to}&shipmentStatus=COMPLETED`
      );
    }
    return axios.get(
      `${BASE_URLs}otz-orders/?page=${page}&limit=${limit}&shipmentStatus=COMPLETED`
    );
  }
  // if (!sortfield && !from && !to) {
  //   console.log("sortfield", "from", "to");
  //   return axios.get(
  //     `${BASE_URL}otz-orders/?page=${page}&limit=${limit}&shipmentStatus=COMPLETED`
  //   );
  // }
};

export const searchOtzSalesOrders = (page, limit, searchString) => {
  return axios.get(
    `${BASE_URLs}otz-orders/search?searchStr=${searchString}&page=${page}&shipmentStatus=COMPLETED`
  );
};

export const SearchShelfNumber = (searchstring) => {
  return axios.get(
    `${BASE_URLs}shelf-numbers/search?page=1&limit=10&searchStr=${searchstring}`
  );
};

export const getShipmentId = (data) => {
  return axios.post(`${BASE_URLs}shipments/`, data);
};

export const searchShipmentId = (searchString) => {
  return axios.get(
    `${BASE_URLs}orders/?orderId=${searchString}&shipmentStatus=PACKED&platformCode=AMAZONIND`
  );
};

// Update multiple Status
export const UdateMultipleStatus = (id, data) => {
  return axios.patch(`${BASE_URLs}/orders/${id}`, data);
};

export const downloadECOrder = (from, to, EC) => {
  if (EC) {
    return axios.get(
      `${BASE_URLs}orders/?orderDate[gte]=${from}&orderDate[lt]=${to}&platformCode=${EC}&limit=10000`
    );
  } else {
    return axios.get(
      `${BASE_URLs}orders/?orderDate[gte]=${from}&orderDate[lt]=${to}&limit=10000`
    );
  }
};

export const downloadOtzOrder = (from, to) => {
  return axios.get(
    `${BASE_URLs}otz-orders/?orderDate[gte]=${from}&orderDate[lt]=${to}&limit=10000`
  );
};

export const downloadSalesCsv = (from, to, platform) => {
  if (platform) {
    return axios.get(
      `${BASE_URLs}orders/?orderDate[gte]=${from}&orderDate[lt]=${to}&shipmentStatus=COMPLETED&platformCode=${platform}`
    );
  } else {
    return axios.get(
      `${BASE_URLs}orders/?orderDate[gte]=${from}&orderDate[lt]=${to}&shipmentStatus=COMPLETED`
    );
  }
};

export const ordersTotal = (startDate, endDate) => {
  return axios.get(
    `${BASE_URLs}orders/total?start_date=${startDate}&end_date=${endDate}`
  );
};

// Product Creation APIs

export const AsinSearch = (Asin, Platform) => {
  let myheaders = {
    "axesso-api-key": "0a9e1c4d-59b0-40c0-84f1-8b7ebb2d15f9",
  };
  var instance = axios.create();
  delete instance.defaults.headers.common["Authorization"];
  if (Platform === "AMAZONJP") {
    axios
      .get(
        `http://api-prd.axesso.de/amz/amazon-lookup-product?url=https://www.amazon.co.jp/dp/${Asin}`,
        {
          headers: myheaders,
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

// Product Search
export const SearchProductTable = (sku) => {
  //Just for the search field
  return axios.get(
    `${BASE_URLs}/products/?skuCode=${sku}&page=1&limit=1&activeStatus=true`
  );
};

export const getProduct = (id) => {
  //Just for the search field
  return axios.get(`${BASE_URLs}/products/${id}`);
};

export const createOrder = (data) => {
  return axios.post(`${BASE_URLs}/orders`, data);
};

export const updateIssue = (issueId) => {
  return axios.put(`${BASE_URLs}issues/${issueId}`, { resolved: true });
};

// issues
export const issues = (page, limit, sortOrder, sortfield, action) => {
  if (sortfield) {
    if (action === "resolved") {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}issues/?page=${page}&limit=${limit}&sort=${sortfield}&resolved=true`
        );
      } else {
        return axios.get(
          `${BASE_URLs}issues/?page=${page}&limit=${limit}&sort=-${sortfield}&resolved=true`
        );
      }
    } else if (action === "unresolved") {
      // unresolved
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}issues/?page=${page}&limit=${limit}&sort=${sortfield}&resolved=false`
        );
      } else {
        return axios.get(
          `${BASE_URLs}issues/?page=${page}&limit=${limit}&sort=-${sortfield}&resolved=false`
        );
      }
    } else {
      if (sortOrder === "desc") {
        return axios.get(
          `${BASE_URLs}issues/?page=${page}&limit=${limit}&sort=${sortfield}`
        );
      } else {
        return axios.get(
          `${BASE_URLs}issues/?page=${page}&limit=${limit}&sort=-${sortfield}`
        );
      }
    }
  }
  if (!sortfield) {
    if (action === "resolved") {
      return axios.get(
        `${BASE_URLs}issues/?page=${page}&limit=${limit}&resolved=true&sort=-createdAt`
      );
    } else if (action === "unresolved") {
      // unresolved
      return axios.get(
        `${BASE_URLs}issues/?page=${page}&limit=${limit}&resolved=false&sort=-createdAt`
      );
    }
  }
  return axios.get(
    `${BASE_URLs}issues/?page=${page}&limit=${limit}&sort=-createdAt`
  );
};

export const getAdvanceOrders = (page, limit, from, to) => {
  if (from && to) {
    console.log("from", from);
    console.log("to", to);
    return axios.get(
      `${BASE_URLs}advance-orders/?page=${page}&limit=${limit}&sort=-createdAt&deliveryDate[gte]=${from}&deliveryDate[lte]=${to}`
    );
  }
  return axios.get(
    `${BASE_URLs}advance-orders/?page=${page}&limit=${limit}&sort=-createdAt`
  );
};

export const getSearchResultAdvanceOrders = (page, limit, orderId) => {
  return axios.get(
    `${BASE_URLs}advance-orders/?page=${page}&limit=${limit}&sort=-createdAt&orderId=${orderId}`
  );
};

export const updateAdvanceOrder = (orderId, data) => {
  return axios.put(`${BASE_URLs}advance-orders/${orderId}`, data);
};

export const postSimulationData = (data, id) => {
  return axios.put(`${BASE_URLs}simulation/${id}/`, data);
};

export const fetchSimulation = (page, limit, sort = false, sortOrder) => {
  if (sort) {
    if (sortOrder === "desc") {
      return axios.get(
        `${BASE_URLs}simulation/?page=${page}&limit=${limit}&sort=${sort}`
      );
    }
    return axios.get(
      `${BASE_URLs}simulation/?page=${page}&limit=${limit}&sort=-${sort}`
    );
  }
  return axios.get(`${BASE_URLs}simulation/?page=${page}&limit=${limit}`);
};

export const createSimulation = (data) => {
  return axios.post(`${BASE_URLs}simulation/`, { ...data });
};

export const fetchCurrency = () => {
  return axios.get(`${BASE_URLs}/currency`);
};

export const fetchmonths = () => {
  return axios.get(`${BASE_URLs}user-preferences/5fd0f6dbe6aea93fa655791b`);
};

export const createAdvanceDates = (data) => {
  return axios.post(`${BASE_URLs}user-preferences/`, { ...data });
};
