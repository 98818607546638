export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GETDATAFROMSTORE = "GETDATAFROMSTORE";
export const INIT_CATEGORY = "INIT_CATEGORY";
export const UPADTE_CATEGORY = "UPADTE_CATEGORY";
export const IS_LOADING = "IS_LOADING";
export const UPDATE_CATEGORY_CELL = "UPDATE_CATEGORY_CELL";
export const TOTALCOUNT = "TOTALCOUNT";

export const CREATE_MANUFACTURER = "CREATE_MANUFACTURER";
export const FETCH_MANUFACTURER_SUCCESS = "FETCH_MANUFACTURER_SUCCESS";
export const POST_MANUFACTURER_SUCCESS = "POST_MANUFACTURER_SUCCESS";
export const INIT_MANUFACTURER = "INIT_MANUFACTURER";
export const UPADTE_MANUFACTURER = "UPADTE_MANUFACTURER";
export const TOTALMANUFACTURERCOUNT = "TOTALMANUFACTURERCOUNT";
export const UPADTE_MANUFACTURER_CELL = "UPADTE_MANUFACTURER_CELL";

export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const FETCH_SUPPLIER_SUCCESS = "FETCH_SUPPLIER_SUCCESS";
export const POST_SUPPLIER_SUCCESS = "POST_SUPPLIER_SUCCESS";
export const INIT_SUPPLIER = "INIT_SUPPLIER";
export const UPADTE_SUPPLIER = "UPADTE_SUPPLIER";
export const TOTALSUPPLIERCOUNT = "TOTALSUPPLIERCOUNT";
export const UPADTE_SUPPLIER_CELL = "UPADTE_SUPPLIER_CELL";

export const CREATE_SHIPPINGMETHOD = "CREATE_SHIPPINGMETHOD";
export const FETCH_SHIPPINGMETHOD_SUCCESS = "FETCH_SHIPPINGMETHOD_SUCCESS";
export const POST_SHIPPINGMETHOD_SUCCESS = "POST_SHIPPINGMETHOD_SUCCESS";
export const INIT_SHIPPINGMETHOD = "INIT_SHIPPINGMETHOD";
export const UPADTE_SHIPPINGMETHOD = "UPADTE_SHIPPINGMETHOD";
export const TOTALSHIPPINGMETHODCOUNT = "TOTALSHIPPINGMETHODCOUNT";

export const CREATE_HANDLING_CHARGES = "CREATE_HANDLING_CHARGES";
export const FETCH_HANDLING_CHARGES_SUCCESS = "FETCH_HANDLING_CHARGES_SUCCESS";
export const POST_HANDLING_CHARGES_SUCCESS = "POST_HANDLING_CHARGES_SUCCESS";
export const INIT_HANDLING_CHARGES = "INIT_HANDLING_CHARGES";
export const UPADTE_HANDLING_CHARGES = "UPADTE_HANDLING_CHARGES";
export const TOTALHANDLINGCHARGESCOUNT = "TOTALHANDLINGCHARGESCOUNT";

export const NEW_ORDER_TIME = "NEW_ORDER_TIME";
export const PREVIOUS_ORDER_TIME = "PREVIOUS_ORDER_TIME";
export const NOTIFICATION_ORDERS = "NOTIFICATION_ORDERS";

export const REFRESH_STATUS = "REFRESH_STATUS";
export const REFRESH_OTZ_STATUS = "REFRESH_OTZ_STATUS";

export const NEW_EC_ORDER = "NEW_EC_ORDER";
export const NEW_OTZ_ORDER = "NEW_OTZ_ORDER";

export const NEW_LANG = "NEW_LANG";
export const CHANGE_STATUS = "CHANGE_STATUS";

export const CHANGE_OTZ_STATUS = "CHANGE_OTZ_STATUS";

export const SORT_DATA = "SORT_DATA";
