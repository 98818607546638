/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector, connect } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { validateUser } from "./modules/Auth/_redux/authCrud";

import * as auth from "./modules/Auth/_redux/authRedux";
import { useDispatch } from "react-redux";

export function Routes() {
  const dispatch = useDispatch();

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    // -------------------------------------------------------------------

    // Experimental
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      {/* <Route path="/error" component={ErrorsPage} /> */}
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>

    // --------------------------------------------------------------------------------------------
    // <Switch>
    //   {!isAuthorized && role !== "admin" ? (
    //     /*Render auth page when user at `/auth` and not authorized.*/
    //     <Route>
    //       <AuthPage />
    //     </Route>
    //   ) : (
    //     /*Otherwise redirect to root page (`/`)*/
    //     <Redirect from="/auth" to="/" />
    //   )}

    //   <Route path="/error" component={ErrorsPage} />
    //   <Route path="/logout" component={Logout} />

    //   {!isAuthorized && role !== "admin" ? (
    //     /*Redirect to `/auth` when user is not authorized*/
    //     <Redirect to="/auth/login" />
    //   ) : (
    //     <Layout>
    //       <BasePage />
    //     </Layout>
    //   )}
    // </Switch>
  );
}
